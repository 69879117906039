import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import styled from 'styled-components';
import Section from '../components/Section';
import Metadata from '../components/Metadata';
import Slider from '../components/Slider';
import { StyledContainer } from '../layouts/styles';

const StyledImageParagraphContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;

  p {
    margin-block: 1rem;
  }

  @media (min-width: 768px) {
    margin-bottom: 3rem;
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: repeat(2, 1fr);

    p {
      order: ${(props) => (props.reverse ? '1' : 'unset')};
    }
  }
`;

const StyledList = styled.ul`
  margin-bottom: 2rem;
`;

const StyledImagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4rem;

  @media (min-width: 768px) {
    flex-direction: row;
    margin-bottom: 2rem;
  }
`;

const StyledImageContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;

  @media (min-width: 920px) {
    width: 50%;
  }
`;

function Therapiematerial() {
  return (
    <>
      <Metadata title='Therapiematerial' />
      <StyledContainer>
        <h1>Therapiematerial</h1>
        <Section>
          <StyledList>
            <li>
              Suchen Sie Therapiematerial, welches Ihnen umfangreiche
              Fähigkeitseinstufungen und die gezielte Förderung verschiedener
              Lernbereiche ermöglicht?
            </li>
            <li>
              Haben Sie Kinder in der Förderung, für die die Übertragung von
              skizzierten Bildern auf alltägliche Objekte nicht
              selbstverständlich ist?
            </li>
            <li>
              Sortieren Sie regelmäßig Bilder aus Materialkästen aus, da diese
              nicht der Lebenswirklichkeit der Kinder entsprechen?
            </li>
            <li>
              Möchten Sie Ihre Zeit besser investieren, als in die aufwendige
              Erstellung von Therapiematerial?
            </li>
          </StyledList>
          <StyledImageParagraphContainer>
            <div>
              <p>
                Dann hoffen wir, dass unser Material Ihre Arbeit bereichert.
                Denn Ihre Unterrichtsstrategie kann noch so gut gewählt sein,
                ohne geeignetes Material ist sie nur halb so effektiv.
              </p>
              <p>
                Senden Sie uns Ihre Bestellung bitte an{' '}
                <a href='mailto:info@lernen-rueckenwind.de'>
                  info@lernen-rueckenwind.de{' '}
                </a>
                unter Angabe des Sets, der Anzahl und der Adresse, an welche wir
                das Material versenden dürfen.
              </p>
            </div>
            <StaticImage
              src='../images/Bidkarten-Set_verspielt.jpg'
              alt='Therapiematerial'
            ></StaticImage>
          </StyledImageParagraphContainer>{' '}
        </Section>
        <Section backgroundColor={'primary'}>
          <Slider duration={2000}>
            <StyledImageContainer>
              <StaticImage
                src='../images/Grundlagen-Set_Karton_alleine.jpg'
                alt='Bildkarten Set'
              ></StaticImage>
            </StyledImageContainer>
            <StyledImageContainer>
              <StaticImage
                src='../images/Grundlagen-Set_Karton_geöffnet.jpg'
                alt='Bildkarten Set'
              ></StaticImage>
            </StyledImageContainer>
            <StyledImageContainer>
              <StaticImage
                src='../images/Aufbau-Set_Karton_greifen.jpg'
                alt='Bildkarten Set'
              ></StaticImage>
            </StyledImageContainer>
            <StyledImageContainer>
              <StaticImage
                src='../images/Aufbau-Set_Karton_geöffnet.jpg'
                alt='Bildkarten Set'
              ></StaticImage>
            </StyledImageContainer>
            <StyledImageContainer>
              <StaticImage
                src='../images/Bidkarten-Set_verspielt.jpg'
                alt='Bildkarten Set'
              ></StaticImage>
            </StyledImageContainer>
          </Slider>
        </Section>
        <Section>
          <p>
            In unseren Bildkartenboxen finden Sie eine Vielzahl von Dingen
            abgebildet, die Kinder in ihrer Umwelt entdecken können. Bei der
            Auswahl der Motive haben wir uns an den Wörtern orientiert, die ein
            Kind für gewöhnlich in den ersten Lebensjahren erlernt, dem
            deutschen Grundwortschatz (Primarstufe) sowie an Wörtern, die aus
            unserer Erfahrung für Kinder von Bedeutung sein können.
          </p>
          <h3 style={{ marginBottom: '1rem' }}>Grundlagen-Set</h3>
          <StyledList>
            <li>Set aus 300 unterschiedliche Motiven</li>
            <li>
              Bestehend aus Bildkarten aus unterschiedlichen Kategorien
              (Kleidung, Lebensmittel, Tiere, Möbel, Fahrzeuge, Körperpflege…)
            </li>
            <li>
              Zur Förderung des Sprachverständnisses und der Fähigkeit des
              Benennens sowie der Zuordnung der Bilder nach Kategorien.
            </li>
          </StyledList>
          <p>Preis: 89 Euro (inklusive Versand)</p>
          <h3 style={{ marginBlock: '1rem' }}>Aufbau-Set</h3>
          <StyledList>
            <li>Insgesamt 300 Motive</li>
            <li>
              50 x 3 verschiedene Exemplare (z.B. Auto 1, Auto 2, Auto 3) zur
              Förderung des Sprachverständnisses und der Fähigkeiten des
              Benennens, indem Sie gleichzeitig die Fähigkeit der
              Generalisierung über verschiedene Exemplare testen und fördern.
            </li>
            <li>
              95 Bildkarten die zur Förderung folgender Fähigkeiten genutzt
              werden können: Zuordnen identischer Bilder (z.B. Auto A zu Auto A)
            </li>
            <li>Zuordnen nicht-identischer Bilder (z.B. Katze A zu Katze B)</li>
            <li>12 farbige Bildkarten zum Zuordnen gleicher Farben</li>
            <li>
              16 Bildkarten mit Formen zum Zuordnen gleicher Formen oder nach
              Farben
            </li>
            <li>27 neue Motive</li>
          </StyledList>
          <p>Preis: 89 Euro (inklusive Versand)</p>
          <StyledImagesContainer>
            <StaticImage src='../images/Vorderseite_Beispiel.png' alt='Hund' />
            <StaticImage
              style={{ border: 'var(--border-small)' }}
              src='../images/Rückseite Beispiel.png'
              alt='Bildkarte Rückseite'
            />
          </StyledImagesContainer>
        </Section>
        <Section>
          <p style={{ marginTop: '1rem' }}>
            Die Gestaltung der Bildkarten erlauben einen sehr flexiblen Einsatz:
          </p>
          <StyledList>
            <li>
              eigene Namensgebung auf der Rückseite, sodass Sie Begriffe auf das
              sprachliche Umfeld Ihres Kindes anpassen können
            </li>
            <li>Festlegung des Ziels auf jeder einzelnen Bildkarte</li>
            <li>
              Platz für individuelle Notizen oder Informationen für das
              Förderteam
            </li>
            <li>
              individuelle Festlegung der Reihenfolge und des
              Schwierigkeitsgrads
            </li>
            <li>
              verschiedene Exemplare der Motive, um vor allem die Fähigkeit der
              Generalisierung über verschiedene Darstellungen zu begünstigen und
              prägnante Merkmale der dargestellten Gegenstände und Tiere zu
              erlernen
            </li>
          </StyledList>
          <p>
            Bitte beachten Sie, dass die Bildkarten auch für Kinder, die eine
            alternative Form der Kommunikation nutzen, wie z.B. Gebärden oder
            Talker, einsetzbar sind!
          </p>
          <p>Haben Sie noch Fragen oder Interesse an Ansichtsexemplaren?</p>
          <p>Schreiben Sie uns...</p>
          <a href='mailto:info@lernen-rueckenwind.de'>
            info@lernen-rueckenwind.de
          </a>
        </Section>
      </StyledContainer>
    </>
  );
}

export default Therapiematerial;
